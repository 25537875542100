import axios from "axios";

export class VoucherApi {
  static async getMineSupply(): Promise<IMineSupply[]> {
    return await axios.get("/apiv2/v2/blindbox/mine/supply");
  }
  static async getSumSupply() {
    return await axios.get("/mall-blind-box/mine/supply");
  }
  static async getNewSumSupply(tab_id:number,page = 1,page_size = 20) {
    return await axios.get(`/mall-voucher/mine/supply/${tab_id}`,{params:{
      page,
      page_size
    }});
  }
  static async openedMyVoucher(id: number): Promise<void> {
    return await axios.post(`/apiv2/voucher/${id}`);
  }

  static async getMyVoucherDetailV2(id: number): Promise<IVoucherDetailV2> {
    return await axios.get(`/apiv2/v2/voucher/${id}`);
  }

  static async syntheticUpgrade(
    voucher_ids: number[] = [],
    entity_ids: number[] = []
  ): Promise<ISyntheticCharacter> {
    return await axios.post("/apiv2/v3/voucher/synthetic/upgrade", {
      voucher_ids,
      entity_ids,
    });
  }

  static async getLoopInfo(character = 1, type = 0): Promise<ILoopInfo> {
    return await axios.get("/apiv2/v3/voucher/synthetic/loop", {
      params: { type, character },
    });
  }

  static async offerStatus(token: string): Promise<VoucherRecordStatus> {
    return await axios.post("/apiv2/v2/voucher/offer/status", { token });
  }

  static async getPrize(params?: IPrizeDto): Promise<IPrize[]> {
    const { character = -1, type = 0, is_mine = 0 } = params || {};
    params = { character, type, is_mine };
    const result = await axios.get<IPrize, Array<IPrize>>(
      "/apiv2/v3/voucher/synthetic/prize",
      {
        params,
      }
    );
    if (!result) return [];
    return result.map((row) => ({ ...row, price: row.price * 100 }));
  }

  static async getMyVouchersV2(
    tab_id = 0,
    page = 1,
    page_size = 10
  ): Promise<Array<IVoucherV2>> {
    return await axios.get("/apiv2/v2/voucher", {
      params: { tab_id, page, page_size },
    });
  }
}

export interface IVoucher {
  code_update_time: number;
  group_id: number;
  group_title: string;
  group_cover: string;
  group_introduction: string;
  code_id: number;
}

export interface IVoucherV2 {
  group_title: string;
  code_record: string;
  group_id: number;
  code_is_seal: number;
  group_cover: string;
  code_id: number;
  code_update_time: number;
  group_character: number;
}

export interface IVoucherDetailV2 {
  code_record: string;
  group_title: string;
  group_introduction: string;
  group_status: number;
  group_id: number;
  code_is_seal: number;
  group_cover: string;
  group_detail: string;
  code_belong_to: number;
  code_code_value: string;
  code_update_time: number;
  group_url: string;
  support_recycle:boolean;
  group_character: number;
  detail_id:string;
  product_id:number;
}

export interface IVoucherDetail {
  code_code_value: string;
  code_belong_to: number;
  code_is_seal: number;
  code_update_time: number;
  group_id: number;
  group_title: string;
  group_cover: string;
  group_introduction: string;
  group_detail: string;
  group_url: string;
  group_status: number;
}

export interface ICharacterNumber {
  character: number;
  total: number;
}

export interface ICharacter {
  group_title: string;
  code_record: string;
  group_id: number;
  code_is_seal: number;
  group_cover: string;
  code_id: number;
  code_update_time: number;
  group_character: number;
}

export interface IPreviewCharacter {
  cover: string;
  character: number;
  chance: number;
  level: number;
  price: number;
  id: number;
  title: string;
  old_chance: number;
  supply_type: number;
}

export interface ISyntheticCharacter {
  token: string;
  id: number;
}

export enum VoucherRecordStatus {
  wait, // 等待中
  complete, // 完成
  failure, // 失败
}

export interface characterItem {
  color: string;
  background: string;
  text: string;
  value: number;
}

export interface ILoopInfo {
  character: number;
  need_num: number;
  type: number;
}

export interface IMineSupply {
  cover: string;
  price: number;
  character: characterItem;
  supply_type: number;
  id: number;
  time: number;
  code_conduct_ios: string;
  title: string;
  code_conduct: string;
}

export interface IMineSupplyChar {
  character: characterItem;
  max_count: number;
  children: Omit<IMineSupply, "character">[];
}

export interface IPrize {
  original_chance: number;
  product_id:number,
  product_bazaar_price: number;
  product_price: number,
  id: number;
  detail: string;
  type_name: string;
  recycle_status: boolean;
  chance: number;
  need_num: number;
  need_character: number;
  supply_type: number;
  type: number;
  repertory: number;
  blind_supply_type: number;
  title: string;
  loop_id: number;
  target: number;
  cover: string;
  character: number;
  price: number;
  character_detail: CharacterDetail;
  need_character_detail: CharacterDetail;
}

export interface IPrizeDto {
  character?: number;
  type?: number;
  is_mine?: number;
}

export interface CharacterDetail {
  color: string;
  background: string;
  text: string;
  value: number;
}
