<template>
  <div>
    <tabs
      class="tab"
      @change="onClickTab"
      v-model="active"
      :ellipsis="false"
      sticky
      swipeable
    >
      <tab :title="title">
        <tag-list></tag-list>
        <div ref="supplyBody">
          <template v-if="supplyItems.length > 0">
            <List v-model="loading" @load="load" :finished="finished" finished-text="" :immediate-check="false">
              <div class="supplyall">
                <my-supply-item
                  style="margin-right: 3%; margin-bottom: 20px; width: 30%"
                  v-for="(item, index) in supplyItems"
                  :key="index"
                  :cover="item.cover"
                  :is_gold_vip="item.is_gold_vip"
                  :bazaar_lock="item.bazaar_lock"
                  :title="item.title"
                  :wideHigh="wideHigh"
                  :status="item.status"
                  :recycle="item.support_recycle"
                  :checkTag="checkTag"
                  :checkNow="current_ids.includes(item.id)"
                  :is_seal="item.is_seal"
                  :character="item.character.value"
                  :is-entity="item.supply_type === 2"
                  @click="
                    handleOpenNewPage(
                      item.id,
                      item.type,
                      item.bazaar_lock,
                      item.is_seal,
                      item.status
                    )
                  "
                />
              </div>
              <div
                style="
                  color: #969696;
                  font-size: 12px;
                  width: 100%;
                  text-align: center;
                  margin-top: 10px;
                  padding-bottom: 40px;
                "
                v-if="tipsShow"
              >
                找不到物品？已提货的需在背包中查看哦
              </div>
              <div v-if="tipsShow" style="width:100%;height:80px;padding-bottom: constant(safe-area-inset-bottom);
                  padding-bottom: env(safe-area-inset-bottom);"></div>
            </List>
          </template>
       
            <Empty
             v-else
              class="empty"
              :style="boxLeft"
              tip="当前没有待处理的提货物品，请前往补给箱货在背包查看物品吧"
            />
        </div>
      </tab>
      <tab title="背包">
        <div style="min-height: 600px" ref="supplyBody">
          <div class="tab-content">
            <div
              :class="{ tabContentTitle: true, activeTitle: currentIndex == 0 }"
              @click="checkTab(0)"
            >
              全部
            </div>
            <div
              :class="{ tabContentTitle: true, activeTitle: currentIndex == 1 }"
              @click="checkTab(1)"
            >
              待发货
            </div>
            <div
              :class="{ tabContentTitle: true, activeTitle: currentIndex == 2 }"
              @click="checkTab(2)"
            >
              已发货
            </div>
          </div>
          <template v-if="currentIndex == 0">
            <Empty v-if="allSupplyItem.length == 0" class="empty" :style="boxLeft" tip="暂无数据" />
              <List v-model="loading0" @load="load0" :finished="finished0" finished-text="" >
                <div class="supplyall">
                  <my-supply-item
                    style="margin-right: 3%; margin-bottom: 20px; width: 30%"
                    v-for="(item, index) in allSupplyItem"
                    :key="index"
                    :cover="item.cover"
                    :is_gold_vip="item.is_gold_vip"
                    :bazaar_lock="item.bazaar_lock"
                    :title="item.title"
                    :wideHigh="wideHigh"
                    :recycle="item.support_recycle"
                    :is_seal="item.is_seal"
                    :character="item.character.value"
                    :is-entity="item.supply_type === 2"
                    @click="
                      handleOpenPage(
                        item.id,
                        item.supply_type,
                        item.bazaar_lock,
                        item.is_seal
                      )
                    "
                  />
                  <div
                    style="
                      color: #969696;
                      font-size: 12px;
                      width: 100%;
                      text-align: center;
                      margin-top: 10px;
                    "
                    v-if="tipsShow"
                  >
                    找不到物品？看看是否在提货柜或挂售中哦
                  </div>
              <div v-if="tipsShow" style="width:100%;height:80px;padding-bottom: constant(safe-area-inset-bottom);
                  padding-bottom: env(safe-area-inset-bottom);"></div>

                </div>
              </List>
              
          </template>
          <delivery-list-pass
            :type="currentIndex"
            v-if="currentIndex == 1"
          />
           <delivery-list
            :type="currentIndex"
            v-if=" currentIndex == 2"
          />
        </div>
          
      </tab>
    </tabs>

    <router-link
      v-if="!flutter"
      :to="{ name: 'Welfare' }"
      class="more_welfare_btn"
      style="z-index: 99"
    />
    <check-button
      v-if="active == 0"
      :checkTag="checkTag"
      @ready="ready"
      @push="push"
      @cancel="cancel"
    ></check-button>
    <user-apply-delivery
      v-if="showDelivery"
      :isComponent="true"
      @close="close"
    ></user-apply-delivery>
    <popup v-model="Tips" class="popupAgagin">
    <div style="padding-top:31px;text-align:center;font-weight:800">提货提醒</div>
      <div class="popupAgagin-content">
        提货后，该物品将进入背包中，不可用于回收、合成、挂售。可前往背包查看卡密或物流速度。
      </div>
      <div class="popupAgagin-button">
        <div class="popupAgagin-think" @click="Tips = false">取消</div>
        <div class="popupAgagin-confirm" @click="confirmHandler">确定提货</div>
      </div>
    </popup>
  </div>
</template>

<script>
import { Component, Vue, Ref } from "vue-property-decorator";
import { Tab, Tabs, List, Toast, Popup } from "vant";
import Empty from "@/components/EmptyBox.vue";
import MySupplyItem from "@/components/MySupplyItemNew.vue";
import { VoucherApi } from "@/api/voucher.api";
import InteractionLib from "@/utils/interaction.lib";
import { observer } from "@/utils/common.util";
import DeliveryList from "@/views/mine/components/DeliveryList.vue";
import DeliveryListPass from "@/views/mine/components/DeliveryListPass.vue";
import { checkGoods, openGoods } from "@/api/user-supply.api";
import { getUserSupply, getWaitNum, getDetail } from "@/api/user-supply.api";
import TagList from "./components/TagList.vue";
import CheckButton from "./components/CheckButtonNew.vue";
import userApplyDelivery from "@/views/mine/UserSupplyApplyDelivery.vue";
@Component({
  components: {
    List,
    MySupplyItem,
    Empty,
    Tab,
    Tabs,
    userApplyDelivery,
    DeliveryListPass,
    Popup,
    CheckButton,
    DeliveryList,
    TagList,
  },
})
export default class MySupply extends Vue {
  @Ref("supplyBody") supplyBodyRef;
  supply = [];
  active = 0;
  supplyItems = [];
  sealCard = [];
  unsealCard = [];
  saleCard = [];
  Tips = false
  currentIndex = 0;
  showDelivery = false;
  checkTag = false;
  // <div
  //             :class="{ tabContentTitle: true, activeTitle: currentIndex == 3 }"
  //             @click="checkTab(3)"
  //           >
  //             未激活
  //           </div>
  get flutter() {
    return InteractionLib.isApp();
  }
  get boxLeft() {
    const {clientWidth}  = document.body
    return `marginLeft:${(clientWidth - 200)/2}px`
  }
  title = "";
  loading = false;
  finished = false;
  page = 0;
  page_size = 20;
  allSupplyItem = [];
  page0 = 0;
  page_size0 = 20;
  tipsShow = false;
  finished0 = false;
  loading0 = false;
  async created() {
    this.init();
    let num = await getWaitNum();
    this.title = `提货柜(${num})`;
    if (num == 0) {
      this.active = 1;
    }
    InteractionLib.setWebViewTitle("我的背包");
  }
  async init() {
    const { clientWidth, offsetWidth } = document.body;
    this.wideHigh = (clientWidth || offsetWidth) / 3 - 20 + "px";
    this.active = Number(this.$route.query.num);
    if (this.$route.query.index) {
      this.currentIndex = Number(this.$route.query.index);
    }
    this.load();
    // this.allSupplyItem = await VoucherApi.getNewSumSupply(0);
  }
  ready() {
    this.checkTag = true;
  }
  async confirmHandler(){
    this.Tips = false
    if(this.needAdress){
      return this.showDelivery = true
    }else {
      this.current_ids.forEach(async (id, index) => {
        if (this.adress) {
          await openGoods(id, this.address);
        } else {
          await openGoods(id);
        }
        if (id) {
          this.checkStatus(id, index);
        }
      });
    }     
  }
  close(res) {
    this.showDelivery = false;
    if (res) {
      this.needAdress = false;
      this.nowAdress = res;
      if (this.nowAdress) {
        this.address = {
          address_id: this.nowAdress.address_id,
          consignee: this.nowAdress.consignee,
          phone: this.nowAdress.phone,
          provinces: JSON.stringify(this.nowAdress.provinces),
          city: JSON.stringify(this.nowAdress.city),
          area: JSON.stringify(this.nowAdress.area),
          detailed_address: this.nowAdress.detailed_address,
          zip_code: this.nowAdress.zip_code,
        };
      }
      Toast('正在提货请等待...')
      this.current_ids.forEach(async (id, index) => {
        if (this.nowAdress) {
          await openGoods(id, this.address);
        } else {
          await openGoods(id);
        }
        if (id) {
          this.checkStatus(id, index);
        }
      });
    }
  }
  miss = 0;
  successs = 0;
  async checkStatus(id, index) {
    const checkRes = await checkGoods(id);
    if (checkRes.status == 1) {
      setTimeout(async ()=>{
        await this.checkStatus(id, index);
      },1000)
      return 
    } else if (checkRes.status == -1) {
      this.miss++;
    } else if (checkRes.status == 2) {
      this.successs++;
    }
    if (this.current_ids.length == index + 1) {
      this.checkTag = false;
      setTimeout(async () => {
        this.page = 0;
        this.loading = false;
        this.finished = false;
        this.supplyItems = [];
        await this.load();
        Toast(
          `提货成功${this.successs}件,失败${this.miss}件,成功货品可前往背包查看`
        );
        this.current_ids = [];
        this.nowAdress = ''
        this.needAdress = false
        this.countEntiy = 0
        let num = await getWaitNum();
        this.title = `提货柜(${num})`;
        if (num == 0) {
          this.active = 1;
        }
      }, 100);
      setTimeout(() => {
        this.successs = 0;
        this.miss = 0;
      }, 2000);
      
    }
  }
  nowAdress = {}

  push() {
    if (this.needAdress) {
      return this.Tips = true
    } else {
      if (this.nowAdress) {
        this.address = {
          address_id: this.nowAdress.address_id,
          consignee: this.nowAdress.consignee,
          phone: this.nowAdress.phone,
          provinces: JSON.stringify(this.nowAdress.provinces),
          city: JSON.stringify(this.nowAdress.city),
          area: JSON.stringify(this.nowAdress.area),
          detailed_address: this.nowAdress.detailed_address,
          zip_code: this.nowAdress.zip_code,
        };
      }
      if(this.current_ids.length<=0){
        return Toast('至少选择一项')
      }
      this.Tips = true
    }
  }
  cancel() {
    this.checkTag = false;
    this.current_ids = [];
  }
  async load0() {
    let res = await VoucherApi.getNewSumSupply(
      0,
      ++this.page0,
      this.page_size0
    );
    this.loading0 = false;
    if (res.length < 10) {
      this.finished0 = true
      this.loading0 = true;

      this.tipsShow = true;
    }
    res.forEach((item) => {
      this.allSupplyItem.push(item);
    });
  }

  current_ids = [];
  needAdress = false;
  countEntiy = 0
  data_item_click(id, type) {
    const status = this.current_ids.includes(id);
    const len = this.current_ids.length;
    if (len >= 8 && !status) {
      Toast("选择补给一次最多8个");
      return;
    }
   
    if (status) {
      if (type == 2) {
        this.countEntiy--
      }
      this.current_ids = this.current_ids.filter((cid) => cid !== id);
    } else {
      if (type == 2) {
        this.countEntiy++
      }
      this.current_ids = [id, ...this.current_ids];
    }
    if (this.countEntiy>0) {
        this.needAdress = true;
      }else{
        this.needAdress = false;
      }
  }
  handleOpenPage(id, type, bazaar_lock, is_seal) {
    if (type == 2 && is_seal == false) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/my-delivery-record/${id}`
        );
      }

      return this.$router.push({
        name: "MyDeliveryRecordDetail",
        params: { id },
      });
    }
    if (bazaar_lock == 1) {
      Toast.fail("该补给正在挂售中");
      setTimeout(() => {
        Toast.clear();
      }, 2000);
      return;
    }
    if (InteractionLib.isApp()) {
      if (type === 2) {
        return InteractionLib.openWebView(
          `${location.origin}/my-voucher/${id}/entity`
        );
      } else {
        return InteractionLib.openWebView(
          `${location.origin}/my-voucher/${id}`
        );
      }
    }
    const name = type === 2 ? "MyVoucherEntityDetail" : "MyVoucherDetail";
    this.$router.push({
      name,
      params: { id: String(id) },
      query: { time: String(this.getNow()) },
    });
  }
  async handleOpenNewPage(id, type, bazaar_lock, is_seal, status) {
    if (this.checkTag) {
      if (status == 1|status == 5) return;
      this.data_item_click(id, type);
      return;
    }
    if (type == 2 && is_seal == false) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/my-delivery-record/${id}`
        );
      }

      return this.$router.push({
        name: "MyDeliveryRecordDetail",
        params: { id },
      });
    }
    if (bazaar_lock == 1) {
      Toast.fail("该补给正在挂售中");
      setTimeout(() => {
        Toast.clear();
      }, 2000);
      return;
    }
    if (InteractionLib.isApp()) {
      if (type === 2) {
        return InteractionLib.openWebView(
          `${location.origin}/user-supply/${id}/entity`
        );
      } else {
        return InteractionLib.openWebView(
          `${location.origin}/user-supply/${id}`
        );
      }
    }
    const name = type === 2 ? "UserSupplyEntityDetail" : "UserSupplyDetail";
    this.$router.push({
      name,
      params: { id: String(id) },
      query: { time: String(this.getNow()) },
    });
  }
  wideHigh = "";

  popTips(item) {
    if (item == 1) {
      console.log(item);
    }
  }

  mounted() {
    observer("refreshInit", () => {
      this.init();
    });
    observer("updateGoldBlindBoxStatus", async (e) => {
      //重新获取我的补给状态
      this.supplyItems = [];
      this.page = 0;
      this.loading = false;
      this.finished = false;
      await this.load();
    });
  }
  async onClickTab(res) {
    if (res == 0) {
      this.supplyItems = [];
      this.loading = false;
      this.finished = false
      this.page = 0;
      return this.load();
    }
    if (res == 1) {
      this.allSupplyItem = [];
      this.finished0 = false
      this.loading0 = false;
      this.page0 = 0;
      return this.load0();
    }
  }
  async load() {
    let params = {
      page: ++this.page,
      page_size: this.page_size,  
    };
    let res = await getUserSupply(params);
    this.loading = false;
    if (res.length < 10) {
      this.loading = true;
      this.finished = true
      this.tipsShow = true;
    }
    res.forEach((item) => {
      this.supplyItems.push(item);
    });
  }
  checkTab(index) {
    this.currentIndex = index;
    if (index == 0) {
    }
  }

  getNow() {
    return Math.ceil(Date.now() / 1000);
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  background: white;
  height: 58px;
  display: flex;
  align-content: center;
  padding: 0px 12px;
  justify-content: space-between;
  .tabContentTitle {
    color: #f5f5f5;
    border-radius: 2px;
    background: #f5f5f5;
    width: 30%;
    height: 30px;
    color: #7b7d83;
    margin-right: 14px;
    font-size: 14px;
    margin-top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.activeTitle {
  color: #52b023 !important;
  background: rgba($color: #52b023, $alpha: 0.12) !important;
}
.empty {
  top: 100px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-list {
  padding: 12px;
}

.supplyall {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px;
}

.more_welfare_btn {
  position: fixed;
  background-image: url("~@/assets/svg/more_welfare_1.svg");
  width: 79px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 120px;
  left: -2px;
  z-index: 20;
}

.tab {
  ::v-deep {
    .van-tabs__wrap {
      border-bottom: 1px solid #f5f5f5;
    }
    .van-tab {
      font-weight: 800;
    }

    .van-tab {
      color: #a8abb1;
    }

    .van-tab--active {
      color: black;
      font-weight: 800;
    }

    .van-tabs__line {
      width: 30px;
      background: black;
      height: 2px;
      bottom: 16px;
      z-index: 9;
    }
  }
}
.popupAgagin {
  border-radius: 12px;
  width: 80%;
  .popupAgagin-content {
    font-size: 15px;
    padding:11px 30px 25px 30px;
    color: #9AA1A9;
    text-align: center;
  }
  .popupAgagin-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    .popupAgagin-think {
      border-top: 1px solid #eaeaea;
      border-right: 1px solid #eaeaea;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 19px 37px;
      color: #9aa1a9;
    }
    .popupAgagin-confirm {
      border-top: 1px solid #eaeaea;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #52b023;
      padding: 19px 37px;
    }
  }
}
</style>
