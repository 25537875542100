<template>
  <div>
        <div class="top" style="position: relative">
          <div class="top_item" @click="toCombine" style="margin-left: 12px">
            <img
              class="top_item_img"
              src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87-%E5%90%88%E6%88%90%E5%8D%87%E7%BA%A7%402x.png"
              alt=""
            />
            <div class="top_item_title">合成升级</div>
          </div>
          <div class="top_item" @click="toCombineSelf">
            <img
              class="top_item_img"
              src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87%EF%BC%8F%E5%90%88%E6%88%90%E8%87%AA%E9%80%89%402x%20(1).png"
              alt=""
            />
            <div class="top_item_title">合成自选</div>
          </div>
          <div class="top_item" style="position: relative" @click="tosell">
            <img
              class="top_item_img"
              src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87%EF%BC%8F%E5%90%88%E6%88%90%E8%87%AA%E9%80%89%402x%20(4).png"
              alt=""
            />
            <div class="top_item_title">挂售</div>
          </div>
          <div class="top_item" style="position: relative" @click="toRecyle">
            <img
              class="top_item_img"
              src="https://cdn.vgn.cn/static/recyle/%E5%9B%BE%E6%A0%87%EF%BC%8F%E5%90%88%E6%88%90%E8%87%AA%E9%80%89%402x.png"
              alt=""
            />
            <div class="top_item_title">回收</div>
          </div>
          <div class="supply-detail-mysupply" @click="toSupplyDetail">
            <div>补</div>
            <div>给</div>
            <div>明</div>
            <div>细</div>
          </div>
        </div>
  </div>
</template>

<script>
import { Component, Vue, Ref } from "vue-property-decorator";
import { Tab, Tabs, List, Toast } from "vant";
import Empty from "@/components/EmptyBox.vue";
import MySupplyItem from "@/components/MySupplyItemNew.vue";
import { VoucherApi } from "@/api/voucher.api";
import InteractionLib from "@/utils/interaction.lib";
import { observer } from "@/utils/common.util";
import { getUserSupply,getWaitNum } from "@/api/user-supply.api";
@Component({
  components: {
    List,
    MySupplyItem,
    Empty,
    Tab,
    Tabs,
  },
})
export default class TagList extends Vue {
  async created() {
  }
 
  toRecyle() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/recyle`);
    }
    this.$router.push({ name: "recyle" });
  }

  toCombine() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/synthetic`);
    }
    this.$router.push({ path: "/synthetic" });
  }

  toCombineSelf() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/optional`);
    }
    this.$router.push({ path: "optional" });
  }

  tosell() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sell`);
    }
    this.$router.push({ path: "/sell" });
  }

  toSupplyDetail() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/supply-detail`);
    }
    this.$router.push({ name: "supplydetal" });
  }
}
</script>

<style lang="scss" scoped>
.supply-detail-mysupply {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha: 0.07);
  font-size: 10px;
  color: #999;
  position: absolute;
  height: 77px;
  width: 28px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px 0 0 8px;
  font-weight: 800;
}

.top {
  padding: 20px 0;
  display: flex;
  background: white;
  border-bottom: 1px #f5f5f5 solid;
  align-items: center;
  .top_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    .top_item_img {
      width: 60px;
      height: 50px;
    }

    .top_item_title {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
</style>
