










import { Component, Vue, Ref, Prop, Emit, Watch } from "vue-property-decorator";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
  
  },
})
export default class CheckButton extends Vue {
    @Prop({ default: false })checkTag!: boolean;
    @Watch('checkTag')
    checkTagHandler(){

    }
    @Emit()
    ready(){
      return
    }
    @Emit()
    push(){
      return
    }
    @Emit()
    cancel(){
      return
    }
}
