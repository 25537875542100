


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DeliveryItem from "@/views/mine/components/DeliveryItem.vue";
import { List } from "vant";
import BlindBoxApi, { Deliver } from "@/api/blind-box.api";
import EmptyBox from "@/components/EmptyBox.vue";

@Component({
  components: { EmptyBox, DeliveryItem, List },
})
export default class DeliveryListPass extends Vue {
  @Prop({ default: 1 }) private readonly type!: number;
  @Watch("type")
  
  items: Deliver[] = [];
  page = 0;
  page_size = 10;
  loading = false;
  finished = false;
  get boxLeft() {
    const { clientWidth } = document.body;
    return `marginLeft:${(clientWidth - 200) / 2 + 100}px`;
  }
  get empty(): boolean {
    const { items } = this;
    return !items.length;
  }

  mounted() {
     this.page = 0;
    this.loadData();
  }
  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, items } = this;
    const rows = await BlindBoxApi.getDeliver(this.type, page, page_size).catch(
      (err) =>{
         console.log(err)
         return []
      }
    );
    this.loading = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.items = page > 1 ? [...items, ...rows] : rows;
  }
}
